import React from "react";
import { PropsWithLoadingState } from "../../utilities";
import { Box, Card, Skeleton, Stack, Typography } from "@mui/material";


export interface INewsItemProps {
    /** The title of the news story */
    title: string;
    /** The subtitle of the news story */
    subtitle: string;
    /** The timestamp of the news story */
    timestamp: string | Date;
    /** The main image url of the news story */
    image: string;
    /** Callback on press on item */
    onClick: () => void;
    /** The orientation of the news item layout. @default horizontal */
    orientation?: 'vertical' | 'horizontal'
}

export const NewsItem = ({
    title,
    subtitle,
    timestamp,
    image,
    onClick,
    orientation = 'horizontal',
    loading
}: PropsWithLoadingState<INewsItemProps>) => {
    return (
        <Card onClick={onClick}
            sx={{
                height: '100%',//take all the height it can
            }}
        >
            <Stack direction={orientation === 'horizontal' ? 'row' : 'column'} height={'100%'}>
                <Box sx={{ flex: 1, aspectRatio: 1 / 1, width: '100%', height: '100%', overflow: 'hidden' }}>
                    {!loading ? <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={image} alt={title} /> : <Skeleton variant="rectangular" height={'100%'} />}
                </Box>
                <Stack direction={'column'} p={1} justifyContent={'space-between'} alignItems={'stretch'} flex={0.5}>
                    <Typography variant='h5' fontWeight={'bold'}>{!loading ? title : <Skeleton />}</Typography>
                    <Typography variant='body1' whiteSpace={'normal'}>{!loading ? subtitle : <Skeleton />}</Typography>
                    <Typography variant='caption' alignSelf={!loading ? 'end' : 'stretch'}>{!loading ? typeof timestamp === 'string' ? timestamp : timestamp.toDateString() : <Skeleton />}</Typography>
                </Stack>
            </Stack>
        </Card >
    )
}