import React from "react";
import firebase from "firebase/app";
import { useQuery } from "react-query";
import { ForumPostCard } from "../../components/forums/ForumPostCard/ForumPostCard";
import { ForumPostsSection } from "../../components/forums/ForumPostsSection/ForumPostsSection";
import { getPostsList } from "../../services/forums";
import { EForumTopic } from "../../utilities/forums";

interface IForumRelatedPostsContainerProps {
    /** The topic to filter with */
    topic: EForumTopic;
    /** The post id to relate to (allow to exclude it to the search too) */
    postId: string;
    /** Callback Navigation to specific forum post */
    navigateToPost: (postId: string) => void;
}
/** Container which takes care of fetching posts related to a topic */
export const ForumRelatedPostsContainer = ({ topic, postId, navigateToPost }: IForumRelatedPostsContainerProps) => {
    const { data, isLoading, isError } = useQuery(
        ["relatedPosts", postId],
        () => getPostsList(topic,
            {
                limit: 3,
                where: [{
                    fieldPath: firebase.firestore.FieldPath.documentId(),
                    opStr: "!=",
                    value: postId
                }]
            }));

    if (isLoading) {
        return <ForumPostCard loading />
    }

    if (isError || !data) {
        return null;
    }

    return <ForumPostsSection title={"More from this topic"}
        topic={topic}
        posts={data.map(post => ({
            title: post.title,
            author: { displayname: post.author.display_name, avatar: post.author.avatar_url || '' },
            content: post.content,
            onClick: () => navigateToPost(post.id),
        }))}
    />
}