import React, { PropsWithChildren } from "react";
import { NavLink, Outlet } from "react-router-dom";
import { Divider, IconButton, Stack, Typography } from "@mui/material";
import { LanguageMenu } from "../LanguageMenu";
import { buildPath, ROUTE_PATH } from "../../utilities/navigation";
import { Facebook, LinkedIn } from "@mui/icons-material";
import { FACEBOOK_LINK, LINKEDIN_LINK, WEBSITE_CAREER_URL, WEBSITE_NEWS_URL } from "../../types/constants";
import { useTranslation } from "react-i18next";
import { RC_CAREER, RC_MAINTENANCE_MODE, RC_NEWS } from "../../types/remote_config";
import { useRemoteConfig } from "../../services/remoteConfig";

const navLinkStyle = { color: 'inherit', textDecoration: 'inherit' };

export const Footer = () => {
  const { t } = useTranslation();

  const { [RC_CAREER]: FF_Career, [RC_MAINTENANCE_MODE]: FF_Maintenance, [RC_NEWS]: FF_News } = useRemoteConfig();
  const hasCareerLink = FF_Career.enabled && !(FF_Maintenance.enabled && FF_Maintenance.subdomainsAffected.includes("CAREER"));
  const hasNewsLink = FF_News.enabled && !(FF_Maintenance.enabled && FF_Maintenance.subdomainsAffected.includes("NEWS"));

  return (
    <BasicFooter>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
        <NavLink
          to={buildPath(ROUTE_PATH.policies)}
          style={navLinkStyle}
        >
          {t('footer.privacyPolicy')}
        </NavLink>
        <Divider orientation="vertical" flexItem />
        <NavLink
          to={buildPath(ROUTE_PATH.terms)}
          style={navLinkStyle}
        >
          {t('footer.termAndConditions')}
        </NavLink>
        <Divider orientation="vertical" flexItem />
        <NavLink
          to={buildPath(ROUTE_PATH.about)}
          style={navLinkStyle}
        >
          {t('footer.about')}
        </NavLink>
        <Divider orientation="vertical" flexItem />
        <NavLink
          to={ROUTE_PATH.aboutBuild(ROUTE_PATH.faq)}
          style={navLinkStyle}
        >
          {t('footer.faq')}
        </NavLink>
        {
          hasNewsLink &&
          <>
            <Divider orientation="vertical" flexItem />
            <NavLink
              target={"_blank"}
              to={WEBSITE_NEWS_URL}
              style={navLinkStyle}>
              {t('footer.news')}
            </NavLink>
          </>
        }
        {hasCareerLink &&
          <>
            <Divider orientation="vertical" flexItem />
            <NavLink
              target={"_blank"}
              to={WEBSITE_CAREER_URL}
              style={navLinkStyle}>
              {t('footer.careers')}
            </NavLink>
          </>
        }
        <Divider orientation="vertical" flexItem />
        <NavLink
          to={buildPath(ROUTE_PATH.sitemap)}
          style={navLinkStyle}>
          {t('footer.sitemap')}
        </NavLink>
      </Stack>
    </BasicFooter>
  );
};

export const ShopFooterOutlet = () => {
  const { t } = useTranslation();
  return (
    <>
      <NavLink
        target="_blank"
        to={"https://www.printful.com/a/cohostop"}
        style={navLinkStyle}
      >
        {t('footer.shopAffiliate', { shop: "Printful" })}
      </NavLink>
      <Divider orientation="vertical" flexItem />
      <NavLink
        target="_blank"
        to={"https://www.printful.com/policies/terms-of-service"}
        style={navLinkStyle}
      >
        {t('footer.shopTermAndConditions')}
      </NavLink>
      <Divider orientation="vertical" flexItem />
      <NavLink
        target="_blank"
        to={"https://www.printful.com/policies/privacy"}
        style={navLinkStyle}
      >
        {t('footer.shopPrivacyPolicy')}
      </NavLink>
      <Divider orientation="vertical" flexItem />
      <NavLink
        target="_blank"
        to={"https://www.printful.com/policies/returns"}
        style={navLinkStyle}
      >
        {t('footer.shopReturnPolicy')}
      </NavLink>
    </>
  )
}

/** Footer for careers pages (simplified footer) */
export const CareersFooter = () => {
  return <BasicFooter />
}

/** Footer for forums pages (simplified footer) */
export const ForumsFooter = () => {
  const { t } = useTranslation();
  const { [RC_CAREER]: FF_Career, [RC_MAINTENANCE_MODE]: FF_Maintenance } = useRemoteConfig();
  const hasCareerLink = FF_Career.enabled && !(FF_Maintenance.enabled && FF_Maintenance.subdomainsAffected.includes("CAREER"));

  return <BasicFooter>
    <Stack direction={'row'} spacing={1}>
      {/* <NavLink
          className="footer__nav-item"
          activeClassName="is-active"
          to="help"
        >
          Help Center
        </NavLink> */}
      <NavLink
        to={ROUTE_PATH.forums.guideline}
        style={navLinkStyle}
      >
        {t('footer.forumGuideline')}
      </NavLink>
      {hasCareerLink &&
        <>
          <Divider orientation="vertical" flexItem />
          <NavLink
            target={"_blank"}
            to={WEBSITE_CAREER_URL}
            style={navLinkStyle}>
            {t('footer.careers')}
          </NavLink>
        </>
      }
    </Stack>
  </BasicFooter>
}

export const HelpFooter = () => {
  return <BasicFooter />
}

export const NewsFooter = () => {
  return <BasicFooter />
}

/** The basic footer any footer contain */
const BasicFooter = ({ children }: PropsWithChildren<{}>) => {
  const { t } = useTranslation();
  return (
    <Stack direction={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      sx={{
        borderTop: '1px solid lightgray',
        padding: 3,
        backgroundColor: '#f7f7f7',
        textAlign: 'center',
      }}>
      <Typography>{t('footer.allRightReserved', { year: new Date().getFullYear() })}</Typography>
      <Typography>{t('footer.appNameTM')}</Typography>
      <LanguageMenu />
      {children}
      <Stack direction={'row'} spacing={2}>
        {
          SOCIALS.map((social, index) =>
            <IconButton key={`social-${index}`} onClick={social.onClick}>
              {social.icon}
            </IconButton>
          )
        }
      </Stack>
      <Divider sx={{ width: '100%', height: 1, margin: 1 }} />
      <Stack direction={'row'} spacing={1} alignItems={'baseline'}>
        <Outlet />
      </Stack>
    </Stack >
  );
}

/** The list of social media CoHostOp has */
export const SOCIALS = [
  {
    icon: <Facebook />,
    onClick: () => window.open(FACEBOOK_LINK),
  },
  {
    icon: <LinkedIn />,
    onClick: () => window.open(LINKEDIN_LINK)
  }
]