//ALL the constants used around

// The company name to include in the emails
export const APP_NAME = 'CoHostOp';

export const FACEBOOK_LINK = "https://www.facebook.com/cohostop";
export const LINKEDIN_LINK = "https://www.linkedin.com/company/cohostop/";

export const IMAGE_COVER = "https://firebasestorage.googleapis.com/v0/b/co-host-op.appspot.com/o/common%2Fimage_cover_top.png?alt=media&token=81895e0f-bd77-42d5-9e99-e88fc61c3736";
export const IC_FACEBOOK = "https://firebasestorage.googleapis.com/v0/b/co-host-op.appspot.com/o/common%2Fsocial%2Fic_facebook.png?alt=media&token=18c4daeb-f28c-427f-a1fb-7321f4f6684d";
export const IC_INSTAGRAM = "https://firebasestorage.googleapis.com/v0/b/co-host-op.appspot.com/o/common%2Fsocial%2Fic_instagram.png?alt=media&token=aefbb89f-ca69-4e4e-bb57-eeb4f6a06c62";
export const IC_LINKEDIN = "https://firebasestorage.googleapis.com/v0/b/co-host-op.appspot.com/o/common%2Fsocial%2Fic_linkedin.png?alt=media&token=6a0f91aa-64da-466e-b2f1-74b6e3f44ebc";
export const IC_TWITTER = "https://firebasestorage.googleapis.com/v0/b/co-host-op.appspot.com/o/common%2Fsocial%2Fic_twitter.png?alt=media&token=378e9756-7a14-4bb7-94e9-2b84a28315be";

export const PHYSICAL_ADDRESS = "CoHostOp, #175, 8623 Granville St, V6P5A2, Vancouver, BC, Canada";

export const WEBSITE_BASE_URL = "https://cohostop.com";

/*******************************************************
 * SUBDOMAIN
 ******************************************************/
/** Utility function to build the base url with the subdomain in it */
export const buildSubdomainBaseUrl = (subdomain: string) => `https://${subdomain}.cohostop.com`;

export const SUBDOMAIN_CAREERS = "careers";
export const WEBSITE_CAREER_URL = buildSubdomainBaseUrl(SUBDOMAIN_CAREERS);

export const SUBDOMAIN_HELP_CENTER = "help";
export const WEBSITE_HELP_CENTER_URL = buildSubdomainBaseUrl(SUBDOMAIN_HELP_CENTER);

export const SUBDOMAIN_SHOP = "shop";
export const WEBSITE_SHOP_URL = buildSubdomainBaseUrl(SUBDOMAIN_SHOP);

export const SUBDOMAIN_NEWS = "news";
export const WEBSITE_NEWS_URL = buildSubdomainBaseUrl(SUBDOMAIN_NEWS);

export const SUBDOMAIN_DEVS = "developers";
export const WEBSITE_DEVELOPERS_URL = buildSubdomainBaseUrl(SUBDOMAIN_DEVS);

export const SUBDOMAIN_FORUMS = "forums";
export const WEBSITE_FORUMS_URL = buildSubdomainBaseUrl(SUBDOMAIN_FORUMS);
