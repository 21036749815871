import React, { ReactElement, useState } from "react";
import { FlagOutlined, Home, ThumbUp, ThumbUpOutlined } from "@mui/icons-material";
import { Stack, Breadcrumbs, Grid, Typography, Card, Avatar, Button, Divider, Skeleton, Chip, emphasize, IconButton } from "@mui/material";
import { IssueStateView } from "../../IssueStateView";
import { IForumPostCardProps } from "../ForumPostCard/ForumPostCard";
import { IForumPostReplyItemProps, ForumPostReplyItem, IForumPostReplyInputProps, ForumPostReplyInput } from "../ForumPostReplyItem/ForumPostReplyItem";
import { styled } from "@mui/styles";
import { EForumTopic, getNameForTopic } from "../../../utilities/forums";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { ForumPostsSection, IForumPostsSectionProps } from "../ForumPostsSection/ForumPostsSection";
import { ForumRelatedArticlesSection } from "../ForumRelatedArticlesSection/ForumRelatedArticlesSection";
import { PropsWithErrorState, PropsWithLoadingState } from "../../../utilities";

export interface IForumsPostScreenProps extends Pick<IForumPostReplyInputProps, 'onReply' | 'currentUserAvatar'> {
    /** The title of the post */
    title: string;
    /** The timestamp of the post */
    timestamp: string | Date;
    /** The topic under which the post is */
    topic: {
        type: EForumTopic;
        text?: string;//if we want to overwrite the mapped one from the topic.type
        onClick: () => void;
    }
    /** The home button which should return to the home of the forum */
    onHomeClick: () => void;
    /** The content of the post */
    content: string;
    /** The author of the post */
    author: { avatar: string; displayName: string; }
    /** The array of tags for this post (TODO future feature)*/
    // tags: string[];
    /** The like button and information */
    likeButton: {
        /** Boolean indicating if the post has already been liked by the user */
        liked: boolean;
        /** The number of likes of this post */
        likesCount: number;
        /** Callback on the click of the like button on the post */
        onLikeClick: () => void;
    }
    /** Callback on the report button, not shown if not provided */
    onReport?: () => void;
    /** Callback on the reply click itself */
    onReplyClick?: () => void;
    /** The total number of replies (can be different than the length of `replies` due to fetch pagination) */
    repliesCount: number;
    /** The list of replies for this post */
    replies?: Array<ReactElement<IForumPostReplyItemProps>>;
    /** The pagination for the replies (optional as there might be less replies than max replies per page) */
    // pagination?: PaginationProps;//firebase/firestore doesn't do offset
    /** Callback on load more replies */
    onLoadMoreReplies?: () => Promise<any>;
    /** The list of related post for the same topic than the post */
    relatedPosts: IForumPostCardProps[] | ReactElement<IForumPostsSectionProps>;
    /** The list of related help article for this post */
    relatedArticles: IForumPostCardProps[] | ReactElement<IForumPostsSectionProps>;//TODO put article props instead
}

/** UI component for the Forum Post */
export const ForumsPostScreen = ({
    title,
    timestamp,
    topic,
    onHomeClick,
    content,
    author,
    likeButton,
    onReport,
    onReplyClick,
    onReply,
    repliesCount,
    replies,
    onLoadMoreReplies,
    relatedPosts,
    relatedArticles,
    currentUserAvatar,
    loading,
    error,
}: PropsWithErrorState<PropsWithLoadingState<IForumsPostScreenProps>>) => {
    const { t } = useTranslation();
    const [replyOpen, setReplyOpen] = useState(false);
    const [loadingMoreReplies, setLoadingMoreReplies] = useState(false);

    if (!!error) {
        return (
            <IssueStateView
                title={t('forum.post.errorTitle')}
                description={t('forum.post.errorContent')}
                button={(typeof error !== 'boolean' && !!error.onRetryClick) ? {
                    text: t('forum.post.errorButton'),
                    onActionClick: error.onRetryClick,
                } : undefined}
            />
        );
    }

    const handleReplyClick = () => {
        //call parent callback if exist
        onReplyClick?.();
        //open the reply text input
        setReplyOpen(true);
    }

    const handleOnLoadMoreReplies = async () => {
        if (!onLoadMoreReplies) return;
        setLoadingMoreReplies(true);
        await onLoadMoreReplies();
        setLoadingMoreReplies(false);
    }

    return (
        <Stack direction={'column'}>
            <Stack sx={{ p: 3 }} direction={'column'}>
                <Stack direction={'row'} justifyContent={'space-between'}>
                    <Breadcrumbs>
                        <StyledBreadcrumb
                            onClick={onHomeClick}
                            label={t('forum.topic.root')}
                            icon={<Home fontSize="small" />}
                            disabled={!!loading}
                        />
                        {!loading ?
                            <StyledBreadcrumb onClick={topic.onClick} label={topic.text || getNameForTopic(t, topic.type)} />
                            :
                            <StyledBreadcrumb label={""} disabled />
                        }
                    </Breadcrumbs>
                    {!loading && !!onReport && <IconButton onClick={onReport}><FlagOutlined color='error' /></IconButton>}
                </Stack>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={10}>
                        <Typography variant='h1'>
                            {!loading ? title : <Skeleton />}
                        </Typography>
                        <Typography variant='subtitle2'>
                            {!loading ?
                                (typeof timestamp === 'string' ? timestamp : timestamp.toDateString())
                                : <Skeleton />
                            }</Typography>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Card sx={{ p: { xs: 1, sm: 2 } }}>
                            <Stack direction={{ xs: 'row', sm: 'column' }} justifyContent={'start'} alignItems={'center'} spacing={1}>
                                {!loading ?
                                    <Avatar variant='circular' sx={{ width: 56, height: 56 }} src={author.avatar} />
                                    :
                                    <Skeleton variant={"circular"}><Avatar variant='circular' sx={{ width: 56, height: 56 }} /></Skeleton>
                                }
                                <Typography>{!loading ? author.displayName : <Skeleton width={50} />}</Typography>
                            </Stack>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={10}>
                        <Typography>{!loading ? content : <Skeleton />}</Typography>
                    </Grid>
                </Grid>
                {/* <Stack>
                    {tags.map()}
                </Stack> */}
                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    {!loading ?
                        <Button startIcon={likeButton.liked ? <ThumbUp /> : <ThumbUpOutlined />} onClick={likeButton.onLikeClick}>{likeButton.likesCount}</Button>
                        :
                        <Button startIcon={<ThumbUpOutlined />} disabled />
                    }
                    <Button variant='contained' onClick={handleReplyClick} disabled={!!loading}>{t('forum.post.replyButton')}</Button>
                </Stack>
                {!loading && replyOpen &&
                    <ForumPostReplyInput onReply={onReply} onCancel={() => setReplyOpen(false)} currentUserAvatar={currentUserAvatar} />
                }
                <Divider sx={{ m: 2 }} />
                {!loading && !repliesCount ?
                    <Typography variant="h4" textAlign={'center'}>{t('forum.post.replyEmptyContent')}</Typography>
                    :
                    (<>
                        <Typography variant='h3'>
                            {!loading ?
                                t('forum.post.repliesCount', { count: repliesCount })
                                : <Skeleton />}
                        </Typography>
                        {
                            !loading ?
                                replies?.map(reply =>
                                    reply
                                )
                                : new Array(2).fill({}).map((_, index) => <ForumPostReplyItem loading key={`reply-loading-${index}`} />)
                        }
                        {/* {!!pagination && <Pagination sx={{ alignSelf: 'center' }} {...pagination} />} */}
                        {!!onLoadMoreReplies && <LoadingButton onClick={handleOnLoadMoreReplies} loading={loadingMoreReplies}>
                            {t('forum.post.loadMoreRepliesButton')}
                        </LoadingButton>}
                    </>)
                }
            </Stack>
            {
                !loading ?
                    Array.isArray(relatedPosts) ?
                        <ForumPostsSection title={t('forum.post.relatedPosts')} topic={topic.type} posts={relatedPosts} />
                        :
                        relatedPosts
                    : <ForumPostsSection title={t('forum.post.relatedPosts')} posts={{ loading: true, length: 2 }} />
            }
            {
                !loading ?
                    Array.isArray(relatedArticles) ?
                        <ForumRelatedArticlesSection articles={relatedArticles} />
                        :
                        relatedArticles
                    : <ForumRelatedArticlesSection articles={{ loading: true, length: 2 }} />
            }
        </Stack>
    );
}

const StyledBreadcrumb = styled(Chip)(() => {
    const backgroundColor = '#dbd9d9';
    return {
        backgroundColor,
        fontWeight: 'bold',
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
}) as typeof Chip; // TypeScript only: need a type cast here because https://github.com/Microsoft/TypeScript/issues/26591

