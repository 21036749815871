import React, { createContext, useEffect, useState } from 'react';
import { DEFAULT_REMOTE_CONFIG, RemoteConfig } from '../../types/remote_config';
import { remoteConfig } from '../firebase';

export const RemoteConfigContext = createContext<RemoteConfig>(DEFAULT_REMOTE_CONFIG);

const getFormattedRemoteConfig = () => {
    //format the remote config value into the RemoteConfig type
    const allRemoteConfig = remoteConfig.getAll();
    let formattedRemoteConfig = {};
    for (const key of Object.keys(allRemoteConfig)) {
        let value = allRemoteConfig[key].asString();
        try {
            value = JSON.parse(value);
        } catch (e) {
            //value could be pure string|number|boolean, which make them not a valid JSON
            //->ignoring and going with the original value itself as string
        }

        formattedRemoteConfig = {
            ...formattedRemoteConfig,
            [key]: value
        }
    }
    return {
        ...DEFAULT_REMOTE_CONFIG,
        ...formattedRemoteConfig,
        // help: { enabled: true }//overwrite for test TODO remove
        // support: { ...DEFAULT_REMOTE_CONFIG[RC_SUPPORT], enabled: true, }//overwrite for test TODO remove
        // maintenance: { ...DEFAULT_REMOTE_CONFIG.maintenance, enabled: true }//overwrite for test TODO remove
    } as RemoteConfig;
}

export const RemoteConfigProvider: React.FC = ({ children }) => {
    const [isActivated, setIsActivated] = useState(false);
    const [values, setValues] = useState<RemoteConfig>(
        getFormattedRemoteConfig()
    );

    useEffect(() => {
        if (isActivated) return;//if already activated bypass the reactivation

        remoteConfig.fetchAndActivate()
            .then((value) => {
                setIsActivated(true);
                //put the new remote config
                setValues(curValues => ({
                    ...curValues,
                    ...getFormattedRemoteConfig(),
                } as RemoteConfig));
            });
    }, [isActivated]);

    return (
        <RemoteConfigContext.Provider value={values} >
            {children}
        </RemoteConfigContext.Provider>
    );
};