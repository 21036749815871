import React from 'react';
import { Box, Button, Chip, Grid, IconButton, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { PropsWithLoadingState } from "../../utilities";
import { ENewsCategories } from "../../types/db";
import { INewsItemProps, NewsItem } from './NewsItem';
import { INewsTopStoryProps, NewsTopStory } from './NewsTopStory';
import { Facebook, LinkedIn } from '@mui/icons-material';
import { FACEBOOK_LINK, LINKEDIN_LINK } from '../../types/constants';

interface INewsMainScreenProps {
    topStory?: PropsWithLoadingState<INewsTopStoryProps>;
    latestStories: PropsWithLoadingState<INewsItemProps[]>;
    otherStories: PropsWithLoadingState<INewsItemProps[]>;
    seeAllStoriesClick: (category?: ENewsCategories) => void;
}

/** Display for the NewsMainScreen */
export const NewsMainScreen = ({ topStory, latestStories, otherStories, seeAllStoriesClick }: INewsMainScreenProps) => {
    const { t } = useTranslation();

    return (
        <Stack direction={'column'}>
            {/* Top news */}
            {!topStory ?
                <Typography variant='h1'>{t('news.main.title')}</Typography>
                : !!topStory.loading ? <NewsTopStory loading /> : <NewsTopStory {...topStory} />}
            {/* Latest news */}
            <Grid container spacing={1} p={2}>
                <Grid item xs={12}>
                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                        <Typography variant='h2'>{t('news.main.latestNews')}</Typography>
                        <Button onClick={() => seeAllStoriesClick()}>{t('news.stories.seeAllButton')}</Button>
                    </Stack>
                </Grid>
                {
                    !!latestStories.loading ?
                        new Array(latestStories.length || 6).fill({})
                            .map((_, index) =>
                                <Grid item xs={12} sm={6} md={4} key={`latest-story-${index}-loading`}>
                                    <NewsItem loading />
                                </Grid>
                            )
                        : latestStories
                            .map((story, index) =>
                                <Grid item xs={12} sm={6} md={4} key={`latest-story-${index}`}>
                                    <NewsItem {...story} />
                                </Grid>
                            )
                }
            </Grid>
            {/* About us / follow us */}
            <Stack sx={{ p: 3, backgroundColor: '#0002' }} direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent={'center'} alignItems={'center'}>
                <Stack direction={'column'} justifyContent={'center'} alignItems={'center'} spacing={1} flex={1}>
                    <Typography variant='h6' textAlign={'center'}>{t('news.main.followUs')}</Typography>
                    <Stack direction={'row'} spacing={2}>
                        <IconButton onClick={() => window.open(FACEBOOK_LINK)}>
                            <Facebook />
                        </IconButton>
                        <IconButton onClick={() => window.open(LINKEDIN_LINK)}>
                            <LinkedIn />
                        </IconButton>
                    </Stack>
                </Stack>
                <Box sx={{ display: { xs: 'none', md: 'flex' }, aspectRatio: 1 / 1 }} flex={1}>
                    <img style={{ height: '100%', width: '100%', borderRadius: 10, objectFit: 'cover' }} src={'https://firebasestorage.googleapis.com/v0/b/co-host-op.appspot.com/o/news%2FScreen%20Shot%202023-09-12%20at%206.24.44%20PM.png?alt=media&token=9b062fad-5237-4d8a-a13f-1ec943c2b047'} alt={'Follow us on social media'} />
                </Box>
                <Box flex={1} sx={{ display: { xs: 'none', md: 'flex' }, aspectRatio: 1 / 1 }} >
                    <img style={{ height: '100%', width: '100%', borderRadius: 10, objectFit: 'cover' }} src={'https://firebasestorage.googleapis.com/v0/b/co-host-op.appspot.com/o/news%2Fimage_EIoZHO_d_1694570500126_raw.jpg?alt=media&token=8c670d26-3cb8-4eb7-a8a1-0053236c3f42'} alt={'About us'} />
                </Box>
                <Stack direction={'column'} justifyContent={'center'} alignItems={'center'} spacing={1} flex={1}>
                    <Typography variant='h6' textAlign={'center'}>{t('news.main.learnMore')}</Typography>
                    <Button variant={'contained'}>{t('news.about.title')}</Button>
                </Stack>
            </Stack>
            {/* Rest of the news */}
            <Grid container spacing={1} p={2}>
                <Grid item xs={12}>
                    <Typography variant={'h2'}>{t('news.main.categoryTitle')}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction={'row'} justifyContent={'flex-start'} alignItems={'center'} spacing={1} flexWrap={'wrap'}>
                        <Chip key={'all'} label={t('news.stories.categoryOption', { context: 'all' })} />
                        {Object.values(ENewsCategories).map(category =>
                            <Chip key={category}
                                label={t('news.stories.categoryOption', { context: category })}
                                variant={'outlined'}
                                onClick={() => seeAllStoriesClick(category)} />)}
                    </Stack>
                </Grid>
                {
                    !!otherStories.loading ?
                        new Array(otherStories.length || 6).fill({}).map((_, index) =>
                            <Grid item xs={12} sm={6} md={4} key={`other-story-${index}-loading`}>
                                <NewsItem loading />
                            </Grid>
                        )
                        : otherStories.map((story, index) =>
                            <Grid item xs={12} sm={6} md={4} key={`other-story-${index}`}>
                                <NewsItem {...story} />
                            </Grid>
                        )
                }
                <Grid item xs={12}>
                    <Button variant={'outlined'} fullWidth onClick={() => seeAllStoriesClick()}>{t('news.stories.loadMoreButton')}</Button>
                </Grid>
            </Grid>
        </Stack>
    )
}