import React from 'react';
import { Route, Routes } from "react-router"
import { CareersFooter } from "../../../components/Footer/Footer"
import { CareerFaqPage } from "../../../pages/careers/CareerFaqPage"
import { CareerMembersPage } from "../../../pages/careers/CareerMembersPage"
import { CareerPositionPage } from "../../../pages/careers/CareerPositionPage"
import { CareerPositionsPage } from "../../../pages/careers/CareerPositionsPage"
import { CareersHomePage } from "../../../pages/careers/CareersHomePage"
import { CareerUniversityPage } from "../../../pages/careers/CareerUniversityPage"
import { CareersAppBar } from "../../../components/appbar/CareersAppBar"
import { FeatureNotAvailablePage } from '../../../pages/FeatureNotAvailablePage/FeatureNotAvailablePage';
import { MaintenanceModePage } from '../../../pages/MaintenanceModePage';
import { NotFoundPage } from "../../../pages/NotFoundPage"
import { useRemoteConfig } from '../../../services/remoteConfig';
import { RC_CAREER, RC_MAINTENANCE_MODE } from '../../../types/remote_config';
import { ROUTE_PARAMS, ROUTE_PATH } from "../RouterConfig"

export const CareersRoutes = () => {
    const { [RC_CAREER]: FF_Career, [RC_MAINTENANCE_MODE]: FF_Maintenance } = useRemoteConfig();

    if (!FF_Career.enabled) {
        return <FeatureNotAvailablePage />
    }

    if (FF_Maintenance.enabled && FF_Maintenance.subdomainsAffected.includes("CAREER")) {
        return <MaintenanceModePage />
    }

    return (
        <>
            {/* AppBar */}
            <Routes>
                {/* Default App bar */}
                <Route path={"*"} element={<CareersAppBar />} />
            </Routes>
            {/* Content */}
            <Routes>
                <Route path={ROUTE_PATH.home} element={<CareersHomePage />} />
                {/* Faq */}
                <Route path={ROUTE_PATH.careers.faq} element={<CareerFaqPage />} />
                {/* Members */}
                <Route path={ROUTE_PATH.careers.members} element={<CareerMembersPage />} />
                {/* Position list */}
                <Route path={ROUTE_PATH.careers.positions}>
                    <Route index element={<CareerPositionsPage />} />
                    <Route path={`:${ROUTE_PARAMS.jobID}`} element={<CareerPositionPage />} />
                </Route>
                {/* University (internship or entry level) */}
                <Route path={ROUTE_PATH.careers.university} element={<CareerUniversityPage />} />
                {/* Anything else */}
                <Route path={'*'} element={<NotFoundPage />} />
            </Routes>
            {/* Footer */}
            <Routes>
                {/* The footer showing in every other pages */}
                <Route path={"*"} element={<CareersFooter />} />
            </Routes>
        </>
    )
}