import { Edit, FlagOutlined, ThumbUp, ThumbUpOutlined, Send, Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Stack, Avatar, Typography, IconButton, Button, TextField, Divider, Skeleton } from "@mui/material";
import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { PropsWithLoadingState } from "../../../utilities";

export interface IForumPostReplyItemProps extends Pick<IForumPostReplyInputProps, 'onReply' | 'currentUserAvatar'> {
    /** The author of the reply */
    author: { avatar: string; displayname: string };
    /** The timestamp of the reply */
    timestamp: string | Date;
    /** The content of the reply */
    content: string;
    /** The like button */
    likeButton: {
        /** The count of likes for this reply */
        likesCount: number;
        /** Callback on like button clicked */
        onLikeClick: () => void;
        /** Boolean to indicate to user if the reply as already been liked */
        liked: boolean;
    }
    /** Callback on the reply click itself */
    onReplyClick?: () => void;
    /** The total number of replies (can be different than the length of `replies` due to fetch pagination) */
    repliesCount: number;
    /** The list of replies to this reply */
    replies?: Array<ReactElement<IForumPostReplyItemProps>>;
    /** Callback on load more replies */
    onLoadMoreReplies?: () => Promise<any>;
    /** Callback on flag for report click */
    onReportClick: () => void;
    /** Callback on edit click (for author to edit their response), icon not shown if not provided */
    onEditClick?: () => void;
}

/** UI Component for the reply on a post */
export const ForumPostReplyItem = ({
    author,
    timestamp,
    content,
    likeButton,
    onReplyClick,
    repliesCount,
    replies,
    onLoadMoreReplies,
    onReportClick,
    onReply,
    onEditClick,
    currentUserAvatar,
    loading }: PropsWithLoadingState<IForumPostReplyItemProps>) => {
    const { t } = useTranslation();
    const [replyOpen, setReplyOpen] = useState(false);
    const [loadingMoreReplies, setLoadingMoreReplies] = useState(false);

    const handleReplyClick = () => {
        //call parent callback if exist
        onReplyClick?.();
        //open the reply text input
        setReplyOpen(true);
    }

    const handleOnLoadMoreReplies = async () => {
        if (!onLoadMoreReplies) return;
        setLoadingMoreReplies(true);
        await onLoadMoreReplies();
        setLoadingMoreReplies(false);
    }

    return (
        <Stack direction={'column'} spacing={1} padding={1}>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'start'}>
                <Stack direction={'column'}>
                    <Stack direction={'row'} spacing={1} justifyContent={'start'} alignItems={'center'}>
                        {!loading ? <Avatar src={author.avatar} /> : <Skeleton variant={"circular"}><Avatar /></Skeleton>}
                        <Typography variant={'h5'}>{!loading ? author.displayname : <Skeleton width={'20vw'} />}</Typography>
                    </Stack>
                    <Typography variant={'subtitle2'}>
                        {!loading ? (typeof timestamp === 'string') ? timestamp : timestamp.toDateString() : <Skeleton width={'10vw'} />}
                    </Typography>
                </Stack>
                {!loading && <Stack direction={'row'}>
                    {!!onEditClick && <IconButton onClick={onEditClick}><Edit color='primary' /></IconButton>}
                    <IconButton onClick={onReportClick}><FlagOutlined color='error' /></IconButton>
                </Stack>
                }
            </Stack>
            <Typography>{!loading ? content : <Skeleton />}</Typography>
            <Stack direction={'row'} justifyContent={'space-between'}>
                {!loading ?
                    <Button onClick={likeButton.onLikeClick} startIcon={likeButton.liked ? <ThumbUp /> : <ThumbUpOutlined />}>{likeButton.likesCount}</Button>
                    : <Button disabled startIcon={<ThumbUpOutlined />} />
                }
                {!loading ?
                    onReplyClick && <Button variant={'outlined'} onClick={handleReplyClick}>{t('forum.post.replyButton')}</Button>
                    : <Button variant={'outlined'} disabled>{t('forum.post.replyButton')}</Button>}
            </Stack>
            {!loading && replyOpen &&
                <ForumPostReplyInput onReply={onReply} onCancel={() => setReplyOpen(false)} currentUserAvatar={currentUserAvatar} />
            }
            {!!repliesCount &&
                (<Stack direction={'column'} paddingLeft={4} borderLeft={1}>
                    <Typography variant='h6'>{t('forum.post.repliesCount', { count: repliesCount })}</Typography>
                    {!!onLoadMoreReplies && <LoadingButton onClick={handleOnLoadMoreReplies} loading={loadingMoreReplies}>{t('forum.post.loadMoreRepliesButton')}</LoadingButton>}
                    {
                        replies?.map(reply =>
                            reply
                        ) ?? <ForumPostReplyItem loading />
                    }
                    {/* {!!pagination && <Pagination sx={{ alignSelf: 'center' }} {...pagination} />} */}
                </Stack>)
            }
            <Divider />
        </Stack>
    );
}

export interface IForumPostReplyInputProps {
    /** Callback on the send button */
    onReply: (content: string) => Promise<void>;
    /** Callback on the cancel/close button */
    onCancel: () => void;
    /** The current user avatar (to display beside reply text input) */
    currentUserAvatar: string;
}
/** The UI just for the reply input piece (can be reused in the post) */
export const ForumPostReplyInput = ({ onReply, onCancel, currentUserAvatar }: IForumPostReplyInputProps) => {
    const [replyContent, setReplyContent] = useState("");

    const handleReplySentClick = () => {
        //call the parent if the content of the reply
        onReply(replyContent)
            .then(() => {
                //reset the reply text input
                setReplyContent("");
                //close the reply text input (only if succeeded)
                onCancel();
            });
    }

    return (
        <Stack direction={'row'} spacing={1} margin={2} justifyContent={'space-between'} alignItems={'center'}>
            <Avatar variant='circular' sx={{ width: 42, height: 42 }} src={currentUserAvatar} />
            <TextField fullWidth value={replyContent} onChange={(event) => setReplyContent(event.target.value)} />
            <IconButton onClick={handleReplySentClick} disabled={!replyContent} color='primary'><Send /></IconButton>
            <IconButton onClick={onCancel}><Close /></IconButton>
        </Stack>
    )
}