import React from "react";
import { ForumsTopicPostsContainer } from "../../../containers/forums/ForumsTopicPostsContainer";
import { useForumsNavigation } from "../../../hooks/useForumNavigation";
import { BANNER_FORUM_COHOST_PAGE } from "../../../utilities/assets/assetHelper";
import { EForumTopic } from "../../../utilities/forums";

export const ForumsCoHostPage = () => {
    const { navigateToNewPost, navigateToArticle, navigateToPost } = useForumsNavigation()

    const handleCreateNewPost = () => {
        navigateToNewPost(EForumTopic.COHOST)
    }

    return <ForumsTopicPostsContainer
        topic={EForumTopic.COHOST}
        banner={{
            title: "Welcome CoHost!",
            backgroundImageSrc: BANNER_FORUM_COHOST_PAGE,
            subTitle: "Posts related to CoHosts experiences",
            primaryButton: { text: "Start a conversation", onClick: handleCreateNewPost }
        }}
        onCreateNewPostClick={handleCreateNewPost}
        navigateToPost={navigateToPost}
        navigateToArticle={navigateToArticle} />;
}