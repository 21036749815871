import React from "react";
import { useQuery } from "react-query";
import { ForumPostCard } from "../../components/forums/ForumPostCard/ForumPostCard";
import { ForumRelatedArticlesSection } from "../../components/forums/ForumRelatedArticlesSection/ForumRelatedArticlesSection";
import { getTopArticles } from "../../services/help";
import { useRemoteConfig } from "../../services/remoteConfig";
import { RC_HELP } from "../../types/remote_config";
import { EForumTopic } from "../../utilities/forums";

const RELATED_ARTICLES_LIMIT = 3

export interface IForumRelatedArticlesContainerProps {
    /** The topic to filter with */
    topic?: EForumTopic;
    /** Callback Navigation to specific help center article */
    navigateToArticle: (articleId: string) => void;
}

/** Container which takes care of fetching help center articles related to a topic */
export const ForumRelatedArticlesContainer = ({ topic, navigateToArticle }: IForumRelatedArticlesContainerProps) => {
    const { [RC_HELP]: FFHelp } = useRemoteConfig(RC_HELP);
    const { data, isLoading, isError } = useQuery(
        ["relatedArticles", topic],
        () => getTopArticles(topic, { limit: RELATED_ARTICLES_LIMIT }),
        { enabled: FFHelp.enabled }
    );

    if (!FFHelp.enabled) {
        return null;
    }

    if (isLoading) {
        return <ForumPostCard loading />//TODO change to article card
    }

    if (isError || !data) {
        return null;
    }

    return <ForumRelatedArticlesSection
        articles={isLoading ? { loading: true, length: RELATED_ARTICLES_LIMIT }
            : data.map(article => ({
                title: article.title,
                author: { displayname: "Help Center", avatar: '' },
                content: article.description,
                onClick: () => navigateToArticle(article.article_id),
            }))}
    />
}