import firebase from "firebase/app";
import { auth, db } from "../firebase";
import { EForumTopic, ForumLike, ForumPost, ForumReply, FORUMS_COLLECTION, FORUMS_LIKES_COLLECTION, FORUMS_REPLIES_COLLECTION, FORUMS_TOPIC_FIELD, WithID, WithRef } from "../../types/db";
import { applyVariablesToQuery, QueryVariables } from "../db";

const getForumsCollectionRef = () => {
    return db.collection(FORUMS_COLLECTION);
}

/** Get all the post for a certain topic, if no topic provided get all */ //limit?
export const getPostsList = async (topic?: string, variables?: QueryVariables) => {
    let query: firebase.firestore.Query<firebase.firestore.DocumentData>;
    query = getForumsCollectionRef();
    if (!!topic) {
        query = query.where(FORUMS_TOPIC_FIELD, '==', topic)
    }
    query = applyVariablesToQuery(query, variables);

    const data = await query
        .get();

    return data.docs.map(doc => ({
        ...doc,//full doc for pagination
        ...(doc.data() as ForumPost),
        id: doc.id,
    } as WithID<ForumPost, string>))
}

/** Get the information of a specific post */
export const getPostInfo = async (postId: string) => {
    const data = await getForumsCollectionRef().doc(postId).get();
    return {
        ref: data.ref,
        ...data.data()
    } as WithRef<ForumPost>;
}

/** Get the reply for a specific post *///TODO add a limit and potential offset for pagination, and have deepness of fetch in replies
export const getPostReplies = async (postId: string, variables?: QueryVariables) => {
    return getReplies(getForumsCollectionRef().doc(postId), variables);
}

/** Get the likes details for a specific post */ //TODO add a limit and potential offset for pagination
export const getPostLikesInfo = async (postId: string) => {
    const data = await getForumsCollectionRef().doc(postId)
        .collection(FORUMS_LIKES_COLLECTION)
        .get();

    return data.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
    } as WithID<ForumLike, string>));
}

/** Get the replies on a reply */ //TODO add a limit and potential offset for pagination, and have deepness of fetch in replies
export const getReplyReplies = async (args: { postId: string, replyId: string } | { replyRef: firebase.firestore.DocumentReference }, variables?: QueryVariables) => {
    return getReplies(
        ('postId' in args) ?
            getForumsCollectionRef().doc(args.postId)
                .collection(FORUMS_REPLIES_COLLECTION)
                .doc(args.replyId)
            : args.replyRef,
        variables
    )
}

/** Get the replies of a document (can be post or reply) */
const getReplies = async (docRef: firebase.firestore.DocumentReference, variables?: QueryVariables) => {
    let query: firebase.firestore.Query<firebase.firestore.DocumentData>
        = docRef
            .collection(FORUMS_REPLIES_COLLECTION);

    query = applyVariablesToQuery(query, variables);

    const data = await query
        .get();

    return data.docs.map(doc => ({
        ...doc,//adding the whole doc for pagination
        ...(doc.data() as ForumReply),
        id: doc.id,
        ref: doc.ref,
    } as WithRef<WithID<ForumReply, string>>));
}

/** Create a new forum post in the DB */
export const createNewPost = (title: string, content: string, topic: EForumTopic) => {
    const user = auth.currentUser
    if (!user) {
        console.warn('trying to create a post for a non-logged user');
        return Promise.reject();
    }

    //rest of data filed by cloud function (to not have front end being able to change those)
    const data: Pick<ForumPost, 'author' | 'title' | 'content' | 'topic'> = {
        author: {
            id: user.uid,
            display_name: user.displayName || '',
            avatar_url: user.photoURL || ''
        },
        title,
        content,
        topic,
    }

    return getForumsCollectionRef().add(data);
}

/** Create a new reply to a post/reply in the DB */
export const addReplyTo = (docRef: firebase.firestore.DocumentReference, content: string) => {
    const user = auth.currentUser
    if (!user) {
        console.warn('trying to add a reply for a non-logged user');
        return Promise.reject();
    }

    //rest of data filed by cloud function (to not have front end being able to change those)
    const data: Pick<ForumReply, 'author' | 'content'> = {
        author: {
            id: user.uid,
            display_name: user.displayName || '',
            avatar_url: user.photoURL || ''
        },
        content
    }

    return docRef.collection(FORUMS_REPLIES_COLLECTION).add(data)
}

/** Edit a forum post in the DB */
export const editPost = (postRef: firebase.firestore.DocumentReference, title: string, content: string, topic: EForumTopic) => {
    const user = auth.currentUser
    if (!user) {
        console.warn('trying to edit a post for a non-logged user');
        return Promise.reject();
    }

    //rest of data filed by cloud function (to not have front end being able to change those) 
    //(cannot change the author after post created)
    const data: Pick<ForumPost, 'title' | 'content' | 'topic'> = {
        title,
        content,
        topic,
    }

    return postRef.update(data);
}

/** Edit a reply to a post/reply in the DB */
export const editReply = (replyRef: firebase.firestore.DocumentReference, content: string) => {
    const user = auth.currentUser
    if (!user) {
        console.warn('trying to edit a reply for a non-logged user');
        return Promise.reject();
    }

    //rest of data filed by cloud function (to not have front end being able to change those)
    //(cannot change the author after post created)
    const data: Pick<ForumReply, 'content'> = {
        content
    }

    return replyRef.update(data)
}

export const deletePost = (postRef: firebase.firestore.DocumentReference) => {
    const user = auth.currentUser
    if (!user) {
        console.warn('trying to delete a post for a non-logged user');
        return Promise.reject();
    }
    return postRef.delete();
}

export const deleteReply = (replyRef: firebase.firestore.DocumentReference) => {
    const user = auth.currentUser
    if (!user) {
        console.warn('trying to delete a reply for a non-logged user');
        return Promise.reject();
    }
    return replyRef.delete()
}

export const addLikeTo = (docRef: firebase.firestore.DocumentReference) => {
    const user = auth.currentUser
    if (!user) {
        console.warn('trying to delete a reply for a non-logged user');
        return Promise.reject();
    }
    //Note: the duplication check is made by using the user id as doc id
    return docRef.collection(FORUMS_LIKES_COLLECTION).doc(user.uid).set({
        author: {
            id: user.uid,
            display_name: user.displayName,
        }
    })
}

export const removeLikeFrom = async (docRef: firebase.firestore.DocumentReference) => {
    const user = auth.currentUser
    if (!user) {
        console.warn('trying to delete a reply for a non-logged user');
        return Promise.reject();
    }

    return docRef.collection(FORUMS_LIKES_COLLECTION).doc(user.uid)
        .delete();
}

/** Return true if the document is liked by the current user */
export const isLiked = async (docRef: firebase.firestore.DocumentReference) => {
    const user = auth.currentUser
    if (!user) {
        //user not logged in, so cannot see history of likes
        return false;
    }
    //In future update, could use https://firebase.google.com/docs/firestore/query-data/aggregation-queries count()
    return (await docRef.collection(FORUMS_LIKES_COLLECTION).doc(user.uid).get()).exists;
}