import React, { useContext } from 'react';
import { Route, Routes } from "react-router"
import { EAuthType } from '../../../components/AuthContent';
import { ForumsFooter } from "../../../components/Footer/Footer"
import { UserContext } from '../../../context/UserInformation';
import { AuthPage } from '../../../pages/AuthPage';
import { FeatureNotAvailablePage } from '../../../pages/FeatureNotAvailablePage/FeatureNotAvailablePage';
import { ChoForumsAppBar } from '../../../pages/forums/ChoForumsAppBar';
import { ForumsCoHostPage } from '../../../pages/forums/ForumsCoHostPage';
import { ForumsCommunityPage } from '../../../pages/forums/ForumsCommunityPage';
import { ForumsGuestPage } from '../../../pages/forums/ForumsGuestPage';
import { ForumsGuidelinesPage } from '../../../pages/forums/ForumsGuidelinesPage';
import { ForumsHostPage } from '../../../pages/forums/ForumsHostPage';
import { ForumsMainPage } from '../../../pages/forums/ForumsMainPage';
import { ForumsNewPostPage } from '../../../pages/forums/ForumsNewPostPage';
import { ForumsPostPage } from '../../../pages/forums/ForumsPostPage';
import { MaintenanceModePage } from '../../../pages/MaintenanceModePage';
import { NotFoundPage } from "../../../pages/NotFoundPage";
import { useRemoteConfig } from '../../../services/remoteConfig';
import { RC_MAINTENANCE_MODE } from '../../../types/remote_config';
import { RC_FORUM } from '../../../types/remote_config/forums';
import { ProtectedRoute } from '../ProtectedRoute';
import { ROUTE_PARAMS, ROUTE_PATH } from "../RouterConfig"

export const ForumsRoutes = () => {
    const userContext = useContext(UserContext);
    const { [RC_FORUM]: FF_Forum, [RC_MAINTENANCE_MODE]: FF_Maintenance } = useRemoteConfig();

    if (!FF_Forum.enabled) {
        return <FeatureNotAvailablePage />
    }

    if (FF_Maintenance.enabled && FF_Maintenance.subdomainsAffected.includes("FORUM")) {
        return <MaintenanceModePage />
    }

    return (
        <>
            {/* AppBar */}
            <Routes>
                {/* Default App bar */}
                <Route path={"*"} element={<ChoForumsAppBar />} />
            </Routes>
            {/* Content */}
            <Routes>
                <Route path={ROUTE_PATH.home} element={<ForumsMainPage />} />
                {/* Community */}
                <Route path={ROUTE_PATH.forums.community} element={<ForumsCommunityPage />} />
                {/* Host */}
                <Route path={ROUTE_PATH.forums.host} element={<ForumsHostPage />} />
                {/* Cohost*/}
                <Route path={ROUTE_PATH.forums.cohost} element={<ForumsCoHostPage />} />
                {/* Guest */}
                <Route path={ROUTE_PATH.forums.guest} element={<ForumsGuestPage />} />
                {/* Community Guideline */}
                <Route path={ROUTE_PATH.forums.guideline} element={<ForumsGuidelinesPage />} />
                {/** Posts in the forum */}
                <Route path={ROUTE_PATH.forums.posts}>
                    <Route index element={<ForumsMainPage />} />
                    <Route path={`:${ROUTE_PARAMS.postID}`} element={<ForumsPostPage />} />
                </Route>
                {/* Creation of a new post in the forum */}
                <Route path={ROUTE_PATH.forums.newPost} element={
                    <ProtectedRoute
                        isAllowed={!!userContext?.isUserLoggedIn() && !userContext.isUserAnonymous()}
                        redirectPath={ROUTE_PATH.authBuild(ROUTE_PATH.auth_login)}>
                        <ForumsNewPostPage />
                    </ProtectedRoute>
                } />
                {/* User profiles */}
                <Route path={ROUTE_PATH.users}>
                    {/* //TODO do users profile view (under ProtectedRoute) */}
                    <Route index element={<ForumsMainPage />} />
                    {/* User login in CoHostOp to access the Forum */}
                    <Route path={ROUTE_PATH.auth_login} element={<AuthPage authType={EAuthType.LOG_IN} />} />
                    <Route path={ROUTE_PATH.auth_join} element={<AuthPage authType={EAuthType.SIGN_UP} />} />
                    {/* User forum profile (TODO) */}
                    <Route path={`:${ROUTE_PARAMS.userID}`} element={<ForumsMainPage />} />
                </Route>
                {/* Anything else */}
                <Route path={'*'} element={<NotFoundPage />} />
            </Routes>
            {/* Footer */}
            <Routes>
                {/* The footer showing in every other pages */}
                <Route path={"*"} element={<ForumsFooter />} />
            </Routes>
        </>
    )
}