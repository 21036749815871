import { Alert, Snackbar } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useInfiniteQuery, useQuery } from "react-query";
import { ForumPostReplyItem } from "../../components/forums/ForumPostReplyItem/ForumPostReplyItem";
import { ForumsPostScreen, IForumsPostScreenProps } from "../../components/forums/ForumsPostScreen/ForumsPostScreen";
import { UserContext } from "../../context/UserInformation";
import { addLikeTo, addReplyTo, getPostInfo, getPostReplies, isLiked, removeLikeFrom } from "../../services/forums";
import { sendSupportTicket } from "../../services/support";
import { ForumPost, ForumReply, FORUMS_TOPIC_FIELD, WithID, WithRef } from "../../types/db";
import { ForumRelatedArticlesContainer } from "./ForumRelatedArticlesContainer";
import { ForumRelatedPostsContainer } from "./ForumRelatedPostsContainer";
import { ForumReplyContainer } from "./ForumReplyContainer";

/** Maximum reply per page (for pagination) */
const REPLIES_PER_PAGE = 5;

export interface IForumsPostContainerProps {
    /** The post id to fetch and display */
    postId: string;
    /** Callback for navigation to a certain post */
    navigateToPost: (postId: string) => void;
    /** Callback for navigation to a certain topic */
    navigateToTopic: (topic: string) => void;
    /** Callback for navigation to a certain article */
    navigateToArticle: (articleId: string) => void;
}

/** Container taking care of fetching the data of the post and displaying it */
export const ForumsPostContainer = ({ postId, navigateToPost, navigateToTopic, navigateToArticle }: IForumsPostContainerProps) => {
    // const [searchParams, setSearchParams] = useSearchParams();
    // const page = +(searchParams.get(ROUTE_QUERY.page) || 1);
    const userContext = useContext(UserContext);
    const {
        data: dataPost,
        isLoading: isLoadingPost,
        isError: isErrorPost, } = useQuery(
            ["post", postId],
            () => getPostInfo(postId),
            {
                enabled: !!postId,
                retry: false,
                refetchOnWindowFocus: false,
            });

    const { data: dataReplies,
        isLoading: isLoadingReplies,
        refetch: refetchReplies,
        hasNextPage,
        fetchNextPage, } = useInfiniteQuery(
            ["postReplies", postId],
            ({ pageParam }) => getPostReplies(
                postId,
                {
                    limit: REPLIES_PER_PAGE,
                    orderBy: { field: "timestamp", dir: "desc" },
                    startAfter: pageParam
                }),
            {
                enabled: !!postId && !!dataPost,
                refetchOnWindowFocus: false,
                getNextPageParam: (_, pages) => {
                    if (!dataPost?.replies_count) {
                        return undefined
                    }
                    if (pages.length < dataPost.replies_count / REPLIES_PER_PAGE) {
                        const lastPage: WithRef<WithID<ForumReply, string>>[] = pages[pages.length - 1];
                        return lastPage[lastPage.length - 1];//the last item of the last page
                    } else {
                        return undefined;//no more to fetch
                    }
                }
            })

    const [props, setProps] = useState<IForumsPostScreenProps>();

    const [reported, setReported] = useState(false);

    useEffect(() => {
        if (!dataPost || !dataPost.ref) {
            return;
        }
        //register to listening to changes on the data
        return dataPost.ref.onSnapshot(async (snap) => {
            const dataSnap = snap.data() as ForumPost;
            if (!dataSnap) {
                return;
            }
            const liked = await isLiked(snap.ref)
            setProps((currentProps) => {
                if (!!currentProps && currentProps.repliesCount !== dataSnap.replies_count) {
                    refetchReplies()
                }
                return mapPostPropsFromData(
                    {
                        ...(dataSnap),
                        ref: snap.ref,
                        liked,
                    },
                    {
                        navigateToTopic,
                        navigateToPost,
                        navigateToArticle,
                    },
                    userContext?.user?.avatarUrl,
                )
            })
        })
        // no need to include "navigateTo*" function to the dependency
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataPost, refetchReplies, userContext?.user?.avatarUrl]);

    if (isLoadingPost || !props) {
        return <ForumsPostScreen loading />
    }

    if (isErrorPost || !dataPost) {
        return <ForumsPostScreen error={{ message: "", onRetryClick: () => navigateToTopic("") }} />
    }

    const handleReportClick = () => {
        sendSupportTicket({
            type: "report",
            error_message: "reporting",
            feature: "forum",
            document: "post",
            doc_id: postId,
            doc_ref: dataPost.ref,
            user_sender: userContext?.user?.firebaseUser?.uid || ''
        })
            .then(() => setReported(true))
    }

    return <>
        <ForumsPostScreen {...props}
            replies={props.repliesCount === 0 ?
                []
                : isLoadingReplies ?
                    new Array(REPLIES_PER_PAGE).fill({}).map((_, index) => <ForumPostReplyItem loading key={`reply-loading-${index}`} />)
                    :
                    dataReplies?.pages.flatMap(page => {
                        return page.map(reply => <ForumReplyContainer key={`reply-${reply.ref.id}`} reply={reply} />);
                    })
            }
            // pagination={(dataPost.replies_count <= REPLIES_PER_PAGE) ?
            //     undefined
            //     :
            //     {
            //         count: Math.ceil(dataPost.replies_count / REPLIES_PER_PAGE),
            //         page: page,
            //         onChange: (_, value) => setSearchParams({ [ROUTE_QUERY.page]: value.toString() }),
            //     }}
            onLoadMoreReplies={hasNextPage ? fetchNextPage : undefined}
            onReport={handleReportClick}
        />
        <Snackbar autoHideDuration={3000} open={reported} onClose={() => setReported(false)} >
            <Alert onClose={() => setReported(false)} severity="success" sx={{ width: '100%' }}>
                Post reported successfully
            </Alert>
        </Snackbar>
    </>
}


const mapPostPropsFromData = (
    data: WithRef<ForumPost> & { liked: boolean },
    callbacks: {
        navigateToTopic: (topic: string) => void,
        navigateToPost: (postId: string) => void,
        navigateToArticle: (articleId: string) => void,
    },
    currentUserAvatar?: string,
): IForumsPostScreenProps => {
    const handleLikesClick = () => {
        if (data.liked) {
            removeLikeFrom(data.ref);
        } else {
            addLikeTo(data.ref);
        }
    }

    const handleReply = async (content: string) => {
        addReplyTo(data.ref, content)
    }

    return {
        title: data.title,
        timestamp: !!data.timestamp ? new Date(data.timestamp) : "",
        content: data.content,
        author: {
            avatar: data.author.avatar_url || '',
            displayName: data.author.display_name || ''
        },
        topic: {
            type: data.topic,
            onClick: () => callbacks.navigateToTopic(data.topic)
        },
        onHomeClick: () => callbacks.navigateToTopic(""),
        likeButton: {
            liked: data.liked,
            likesCount: data.likes_count || 0,
            onLikeClick: handleLikesClick,
        },
        repliesCount: data.replies_count || 0,
        onReplyClick: () => { },
        onReply: handleReply,
        currentUserAvatar: currentUserAvatar || '',
        relatedPosts: <ForumRelatedPostsContainer
            topic={data[FORUMS_TOPIC_FIELD]}
            postId={data.ref.id}
            navigateToPost={callbacks.navigateToPost}
        />,
        relatedArticles: <ForumRelatedArticlesContainer
            topic={data[FORUMS_TOPIC_FIELD]}
            navigateToArticle={callbacks.navigateToArticle}
        />
    }
}

